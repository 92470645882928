<template>
  <div class="px-4 content">
    <v-tabs
      v-model="tab"
      center-active
      fixed-tabs
      icons-and-text
    >
      <v-tab>
        Volume de Leite
        <v-icon>
          mdi-pail
        </v-icon>
      </v-tab>
      <v-tab>
        Volume de Leite Não Conforme
        <v-icon>
          mdi-pail-remove
        </v-icon>
      </v-tab>
      <v-tab
        @click="incrementTab"
      >
        Dashboard do Volume de Leite
        <v-icon>
          mdi-monitor-dashboard
        </v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <template>
          <base-loading
            v-if="!ready"
          />
          <div
            v-else
          >
            <crud-list
              v-model="volumes"
              :headers="headers"
              total-column

              :slots="[
                'item.data_coleta',
                'item.volume',
                'item.data_referencia',
              ]"
              :permission="'list_volume'"
              :sort-by="['data_referencia','data_coleta']"
              :sort-desc="[true]"
              striped
              export-pdf-xlsx
              :item-height="$vuetify.breakpoint.lg ? 'calc(82vh - 263px)' : 'calc(81vh - 195px)'"
            >
              <template slot="datas">
                <v-col>
                  <data-field
                    v-model="data_inicial"
                    :label="'Data Inicial'"
                    :data-max="data_final"
                    @set="handleDataFilter()"
                  />
                </v-col>
                <v-col>
                  <data-field
                    v-model="data_final"
                    :label="'Data Final'"
                    :data-min="data_inicial"
                    @set="handleDataFilter()"
                  />
                </v-col>
              </template>
              <template v-slot:[`item.data_coleta`]="{ item }">
                <span>{{ item.data_coleta.substring(8, 10) + '/' + item.data_coleta.substring(5, 7) + '/' + item.data_coleta.substring(0, 4) }}</span>
              </template>
              <template v-slot:[`item.data_referencia`]="{ item }">
                <span>{{ item.data_referencia.substring(5, 7) + '/' + item.data_coleta.substring(0, 4) }}</span>
              </template>
              <template v-slot:[`item.volume`]="{ item }">
                <span>{{ (parseInt(item.volume)).toLocaleString('pt-BR') }}</span>
              </template>
            </crud-list>
          </div>
        </template>
      </v-tab-item>
      <v-tab-item>
        <template>
          <volume-condenacao />
        </template>
      </v-tab-item>
      <v-tab-item>
        <template>
          <dashboard-volume :key="tabKey" />
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import VolumesStore, { BOOTSTRAP, DELETE_VOLUME } from '@/store/modules/volumes'
  import { sortDateTime } from '../../utils/sorts'
  import DashboardVolume from './DashboardVolume.vue'
  import VolumeCondenacao from './VolumeCondenacao.vue'

  export default {
    components: {
      DashboardVolume,
      VolumeCondenacao,
    },
    data () {
      return {
        tab: 0,
        tabKey: 0,
        data_inicial: 'from',
        data_final: 'to',
        headers: [
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Período', value: 'data_referencia', width: 'auto', sort: sortDateTime, report: true, styleReport: { halign: 'center', type: 'mesAno' }, styleXLSX: { type: 'mesAno' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data de Coleta', value: 'data_coleta', width: 'auto', sort: sortDateTime, report: true, styleReport: { halign: 'center' }, styleXLSX: { type: 'date' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: true, text: 'Matrícula', value: 'cod_produtor_fornecedor', width: 'auto', report: true, styleReport: { halign: 'center' } },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Produtor', value: 'produtor', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Fazenda', value: 'fazenda', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Unidade de Negócio', value: 'unidade_negocio', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Rota', value: 'rota', width: 'auto', report: true },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Volume', value: 'volume', sum: 'Integer', width: 'auto', report: true, styleReport: { halign: 'right', type: 'number' }, styleXLSX: { type: 'number' } },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Temperatura', value: 'temperatura', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('volumes', ['ready', 'volumes']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('volumes')) { this.$store.registerModule('volumes', VolumesStore) }

      const now = new Date()
      const data_i = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      const data_f = new Date(now.getFullYear(), now.getMonth() + 1, 0)

      this.data_inicial = data_i.toISOString().slice(0, 10)
      this.data_final = data_f.toISOString().slice(0, 10)

      this.BOOTSTRAP({ data_inicial: data_i.toISOString().slice(0, 10), data_final: data_f.toISOString().slice(0, 10) })
    },
    methods: {
      ...mapActions('volumes', [BOOTSTRAP, DELETE_VOLUME]),
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
      },
      incrementTab () {
        this.tabKey = this.tabKey + 1
      },
    },
  }
</script>
