<template>
  <base-loading
    v-if="!ready"
    base-class="fill-height ma-0 flex-column"
  />
  <div
    v-else
  >
    <crud-list
      v-model="condenacao"
      :headers="coluns"
      total-column
      :item-height="$vuetify.breakpoint.lg ? 'calc(82vh - 263px)' : 'calc(81vh - 195px)'"
      :slots="[ 'item.data_referencia' , 'item.data_condenacao', 'item.volume']"
      :sort-by="['data_referencia','data_condenacao']"
      :sort-desc="[true]"
      :permission="'list_volume'"
      export-pdf-xlsx
    >
      <template slot="datas">
        <v-col>
          <data-field
            v-model="data_inicial"
            :label="'Data Inicial'"
            :data-max="data_final"
            @set="handleDataFilter()"
          />
        </v-col>
        <v-col>
          <data-field
            v-model="data_final"
            :label="'Data Final'"
            :data-min="data_inicial"
            @set="handleDataFilter()"
          />
        </v-col>
      </template>
      <template v-slot:[`item.data_referencia`]="{ item }">
        <span>{{ moment(item.data_referencia).format('MM/YYYY') }}</span>
      </template>
      <template v-slot:[`item.data_condenacao`]="{ item }">
        <span>{{ moment(item.data_condenacao).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:[`item.volume`]="{ item }">
        <span>{{ (parseInt(item.volume)).toLocaleString('pt-BR') }}</span>
      </template>
    </crud-list>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import CondenacaoStore, { BOOTSTRAP } from '@/store/modules/condenacao'
  import moment from 'moment'

  export default {
    components: {
    },

    data () {
      return {
        moment,
        tab: 0,
        data_inicial: null,
        data_final: null,
        coluns: [
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Período', value: 'data_referencia', report: true, styleReport: { halign: 'center', type: 'mesAno' }, styleXLSX: { type: 'mesAno' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data Condenação', value: 'data_condenacao', report: true, styleReport: { halign: 'center' }, styleXLSX: { type: 'date' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: true, text: 'Matrícula', value: 'cod_produtor_fornecedor', width: 'auto', report: true, styleReport: { halign: 'center' } },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Produtor', value: 'produtor', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Fazenda', value: 'fazenda', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Unidade de Negócio', value: 'unidade_negocio', report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Volume', sum: 'Integer', value: 'volume', report: true, styleReport: { halign: 'right', type: 'number' }, styleXLSX: { type: 'number' } },
          { align: 'center', sortable: true, groupable: true, floatingfilter: true, text: 'Código', value: 'codigo', report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Descrição', value: 'descricao_condenacao', report: true },
        ],
      }
    },
    computed: {
      ...mapState('condenacao', ['condenacao', 'ready']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('condenacao')) { this.$store.registerModule('condenacao', CondenacaoStore) }

      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 3)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = data_i
      this.data_final = data_f

      this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
    },
    methods: {
      ...mapActions('condenacao', [BOOTSTRAP]),
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
      },
    },
  }
</script>
