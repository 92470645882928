<template>
  <div>
    <base-period-select
      v-model="searchDates"
      class="justify-start ml-2"
      :bootstrap="BOOTSTRAP_DASHBOARD"
      :select-periodos-names="selectPeriodosNames"
      :mensal="true"
    />
    <base-loading
      v-if="!ready"
      base-class="fill-height ma-0 flex-column"
    />
    <v-container
      v-else
      fluid
    >
      <v-row
        class="pt-0 pb-0 py-0"
      >
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6"
        >
          <material-card
            v-if="dashboard.top_10"
            color="primary"
            :title="'Os 10 maiores de ' + moment(searchDates.from).format('DD/MM/YYYY') + ' a ' + moment(searchDates.to).format('DD/MM/YYYY')"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="4"
            style="min-width: '100%';"
          >
            <v-card
              style="margin: 0px;"
              outlined
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th
                      style="text-align: start; font-size: 13px; font-weight: bold;"
                      min-width="15px"
                    >
                      Ordem
                    </th>
                    <th
                      min-width="225px"
                      style="text-align: left; font-size: 13px; font-weight: bold;"
                    >
                      Produtor
                    </th>
                    <th
                      min-width="225px"
                      style="text-align: left; font-size: 13px; font-weight: bold;"
                    >
                      Fazenda
                    </th>
                    <th
                      width="150px"
                      style="text-align: right; font-size: 13px; font-weight: bold;"
                    >
                      Volume
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, indrow) in dashboard.top_10"
                    :key="indrow"
                  >
                    <td
                      style="text-align: right; color: blue; font-size: 13px; font-weight: bold;"
                      width="15px"
                    >
                      {{ item.ordem !== '' ? item.ordem + 'º' : '' }}
                    </td>
                    <td
                      width="225px"
                      style="text-align: left;white-space: nowrap;color: blue;"
                    >
                      {{ item.produtor }}
                    </td>
                    <td
                      width="225px"
                      style="text-align: left;white-space: nowrap;color: blue;"
                    >
                      {{ item.fazenda }}
                    </td>
                    <td
                      width="150px"
                      style="text-align: right;color: blue; padding-left: 10px; padding-right: 10px;"
                    >
                      {{ item.volume !== -1 ? currencyFormatter( item.volume, 0) + ' L' : '' }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </material-card>
          <material-card
            v-if="dashboard.bottom_10"
            color="primary"
            :title="'Os 10 menores de ' + moment(searchDates.from).format('DD/MM/YYYY') + ' a ' + moment(searchDates.to).format('DD/MM/YYYY')"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="4"
            style="min-width: '100%';"
          >
            <v-card
              style="margin: 0px;"
              outlined
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th
                      style="text-align: start; font-size: 13px; font-weight: bold;"
                      width="15px"
                    >
                      Ordem
                    </th>
                    <th
                      width="225px"
                      style="text-align: left; font-size: 13px; font-weight: bold;"
                    >
                      Produtor
                    </th>
                    <th
                      width="225px"
                      style="text-align: left; font-size: 13px; font-weight: bold;"
                    >
                      Fazenda
                    </th>
                    <th
                      width="150px"
                      style="text-align: right; font-size: 13px; font-weight: bold;"
                    >
                      Volume
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, indrow) in dashboard.bottom_10"
                    :key="indrow"
                  >
                    <td
                      style="text-align: right; color: red; font-size: 13px; font-weight: bold;"
                      width="15px"
                    >
                      {{ item.ordem !== '' ? item.ordem + 'º' : '' }}
                    </td>
                    <td
                      width="225px"
                      style="text-align: left;white-space: nowrap;color: red;"
                    >
                      {{ item.produtor }}
                    </td>
                    <td
                      width="225px"
                      style="text-align: left;white-space: nowrap;color: red;"
                    >
                      {{ item.fazenda }}
                    </td>
                    <td
                      width="150px"
                      style="text-align: right;color: red; padding-left: 10px; padding-right: 10px;"
                    >
                      {{ item.volume !== -1 ? currencyFormatter( item.volume, 0) + ' L' : '' }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </material-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-card
            color="primary"
            :title="'Mapa do Fornecimento de ' + moment(searchDates.from).format('DD/MM/YYYY') + ' a ' + moment(searchDates.to).format('DD/MM/YYYY')"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="4"
          >
            <v-card
              style="margin: 0px 0px 5px 0px"
              outlined
            >
              <v-row
                justify="center"
                class="px-5"
              >
                <v-radio-group
                  v-model="tipo"
                  row
                  style="padding-top: 15px; padding-bottom: 12px;"
                  @click="key = key + 1"
                >
                  <v-radio value="0">
                    <template v-slot:label>
                      <div>
                        <strong
                          style="color: rgb(0, 0, 255);"
                        >Os 10 maiores Fornecedores</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="1">
                    <template v-slot:label>
                      <div><strong class="error--text">Os 10 menores Fornecedores</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-row>
            </v-card>
            <v-card
              style="margin: 0px;"
              outlined
            >
              <div
                v-if="tipo === '0'"
              >
                <gmap-map
                  key="key"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 775px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 760px;' : 'width: 100%;height: 760px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.topmap_volume"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon0"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.ordem }}º</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Volume: </span><span style="font-weight: bold; color: rgb(0, 0, 255)">{{ currencyFormatter( element.volume, 0 ) }} Litros</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo === '1'"
              >
                <gmap-map
                  key="key"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 775px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 760px;' : 'width: 100%;height: 760px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.bottommap_volume"
                    :key="`b-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon1"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.ordem }}º</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Volume: </span><span style="font-weight: bold; color: red">{{ currencyFormatter( element.volume, 0 ) }} Litros</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
            </v-card>
            <template v-slot:actions>
              <p class="mb-1">
                <span style="font-weight: bold;">* Produtores sem dados de localização, não são visualizados.</span>
              </p>
            </template>
          </material-card>
        </v-col>
        <v-col
          cols="12"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_intervalo"
            :options="optInter"
            :data="dashboard.grafico_intervalo.data"
            type="Bar"
            color="primary"
            title="(%) Produtores por Média Diária de Captação"
            icon="mdi-pail"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
          />
        </v-col>
        <v-col
          cols="12"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_media_dia"
            :data="dashboard.grafico_media_dia.data"
            :options="optLine()"
            type="Line"
            color="primary"
            title="Histórico da Captação por Média Diária"
            icon="mdi-pail"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
          />
        </v-col>
        <v-col
          cols="12"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_volume"
            :options="optVolume()"
            :data="dashboard.grafico_volume.data"
            type="Bar"
            color="primary"
            title="Comparativo do Histórico da Captação por mês"
            icon="mdi-pail"
            elevation="4"
          >
            <template v-slot:actions>
              <v-card
                class="mt-0 px-1 mx-1"
                style="border-radius: 16px"
                elevation="3"
                width="19.5%"
                justify="center"
              >
                <h4
                  class="pb-1 mt-2 px-1 text-center"
                  style="text-shadow: grey 0.1em 0.1em 0.2em;"
                >
                  Volume Total
                </h4>
                <div
                  class="py-1 pb-2 px-1 text-center"
                  style="font-size: 13px; font-weight: 600; line-height: 1.375rem; letter-spacing: 0.0071428571em; color: rgb(0, 54, 119);"
                >
                  <span>{{ currencyFormatter( dashboard.total_volume, 0 ) }} Litros</span>
                </div>
              </v-card>
              <v-card
                class="mt-0 px-1 mx-1"
                style="border-radius: 16px"
                elevation="3"
                width="19.5%"
                justify="center"
              >
                <h4
                  class="pb-1 mt-2 px-1 text-center"
                  style="text-shadow: grey 0.1em 0.1em 0.2em;"
                >
                  Média Mensal
                </h4>
                <div
                  class="py-1 pb-2 px-1 text-center"
                  style="font-size: 13px; font-weight: 600; line-height: 1.375rem; letter-spacing: 0.0071428571em; color: rgb(0, 54, 119);"
                >
                  <span>{{ currencyFormatter( returnMediaMensal, 0 ) }} Litros</span>
                  <span
                    v-if="crescimentoMedio !== 0"
                    :style="'color:' + ( crescimentoMedio > 0 ? 'green' : 'red' )"
                  >
                    <v-icon
                      v-if="crescimentoMedio < 0 "
                      small
                      color="red darken-3"
                    >
                      mdi-arrow-down-bold
                    </v-icon>
                    <v-icon
                      v-if="crescimentoMedio > 0 "
                      small
                      color="green"
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                    <span>({{ currencyFormatter( crescimentoMedio, 2 ) }}%)</span>
                  </span>
                </div>
              </v-card>
              <v-card
                class="mt-0 px-1 mx-1"
                style="margin-top: 14px; border-radius: 16px"
                elevation="3"
                width="19.5%"
                justify="center"
              >
                <h4
                  class="pb-1 mt-2 px-1 text-center"
                  style="text-shadow: grey 0.1em 0.1em 0.2em;"
                >
                  Média diaria
                </h4>
                <div
                  class="py-1 pb-2 px-1 text-center"
                  style="font-size: 13px; font-weight: 600; line-height: 1.375rem; letter-spacing: 0.0071428571em; color: rgb(0, 54, 119);"
                >
                  <span>{{ currencyFormatter( dashboard.media_dia, 0 ) }} Litros</span>
                  <span
                    v-if="crescimentoMedioDiario !== 0"
                    :style="'color:' + ( crescimentoMedioDiario > 0 ? 'green' : 'red' )"
                  >
                    <v-icon
                      v-if="crescimentoMedioDiario < 0 "
                      small
                      color="red darken-3"
                    >
                      mdi-arrow-down-bold
                    </v-icon>
                    <v-icon
                      v-if="crescimentoMedioDiario > 0 "
                      small
                      color="green"
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                    <span>({{ currencyFormatter( crescimentoMedioDiario, 2 ) }}%)</span>
                  </span>
                </div>
              </v-card>
              <v-card
                class="mt-0 px-1 mx-1"
                style="margin-top: 14px; border-radius: 16px"
                elevation="3"
                width="19.5%"
                justify="center"
              >
                <h4
                  class="pb-1 mt-2 px-1 text-center"
                  style="text-shadow: grey 0.1em 0.1em 0.2em;"
                >
                  Média diaria {{ meses[moment(searchDates.to).months()] + "/" + moment(searchDates.to).subtract(1, 'year').format('YY') }}
                </h4>
                <div
                  class="py-1 pb-2 px-1 text-center"
                  style="font-size: 13px; font-weight: 600; line-height: 1.375rem; letter-spacing: 0.0071428571em; color: rgb(0, 54, 119);"
                >
                  <span>{{ currencyFormatter( dashboard.media_mes_anterior, 0 ) }} Litros </span>
                </div>
              </v-card>
              <v-card
                class="mt-0 px-1 mx-1"
                style="margin-top: 14px; border-radius: 16px"
                elevation="3"
                width="19.5%"
                justify="center"
              >
                <h4
                  class="pb-1 mt-2 px-1 text-center"
                  style="text-shadow: grey 0.1em 0.1em 0.2em;"
                >
                  Média diaria {{ meses[moment(searchDates.to).months()] + "/" + moment(searchDates.to).format('YY') }}
                </h4>
                <div
                  class="py-1 pb-2 px-1 text-center"
                  style="font-size: 13px; font-weight: 600; line-height: 1.375rem; letter-spacing: 0.0071428571em; color: rgb(0, 54, 119);"
                >
                  <span>{{ currencyFormatter( dashboard.media_mes_corrente, 0 ) }} Litros</span>
                </div>
              </v-card>
            </template>
          </material-chart-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import MaterialCard from '../../components/base/MaterialCard'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import { BOOTSTRAP_DASHBOARD } from '@/store/modules/volumes'
  import Tooltip from 'chartist-plugin-tooltips-updated'
  import Legend from 'chartist-plugin-legend'
  import Swal from 'sweetalert2'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'DashboardVolume',
    components: {
      MaterialChartCard,
      MaterialCard,
    },
    data () {
      return {
        date: new Date(),
        meses: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        currencyFormatter,
        center: { lat: 0, lng: 0 },
        showByIndex: 0,
        tipo: '0',
        key: 0,
        icon0: {
          url: require('../../assets/produtor_blue.png'),
          size: { width: 23, height: 23, f: 'px', b: 'px' },
          scaledSize: { width: 23, height: 23, f: 'px', b: 'px' },
        },
        icon1: {
          url: require('../../assets/produtor_red.png'),
          size: { width: 23, height: 23, f: 'px', b: 'px' },
          scaledSize: { width: 23, height: 23, f: 'px', b: 'px' },
        },

        optInter: {
          low: 0,
          showArea: false,
          stackBars: true,
          axisX: {
            offset: 20,
            showGrid: false,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
            labelInterpolationFnc: function (value) {
              return value + ' %'
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            Legend(),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return currencyFormatter(value, 2) + ' %' + '<br />' + meta + ' Produtores' },
            }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        searchDates: {
          name: 'volume',
          label: '',
          selected: 'Trimestre',
          from: null,
          to: null,
        },
        selectPeriodosNames: [
          'Mês',
          'Trimestre',
          'Semestre',
          'Ano',
          'Selecionar período',
        ],
      }
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('volumes', ['ready', 'dashboard']),
      ...mapGetters(['hasPermission']),
      volumeTotal () {
        let litros = 0
        this.dashboard.grafico_volume.data.series[1].data.map(el => { litros += parseInt(el.value) })
        return litros
      },
      returnMediaMensal () {
        const volume = this.dashboard.total_volume
        if (!volume) return 0
        const meses = this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'months') + 1
        return volume / meses
      },
      crescimentoMedio () {
        let crescimentoMensal = 0

        const meses = this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'months') + 1

        if (meses <= 1) {
          return 0 // Se houver apenas um mês, o crescimento é zero
        }

        const primeiroMes = this.dashboard.grafico_volume.data.series[1].data[0]
        const ultimoMes = this.dashboard.grafico_volume.data.series[1].data[meses - 1]

        if (primeiroMes && primeiroMes.value !== undefined && ultimoMes && ultimoMes.value !== undefined) {
          const valorInicial = parseInt(primeiroMes.value)
          const valorFinal = parseInt(ultimoMes.value)

          if (!isNaN(valorInicial) && !isNaN(valorFinal) && valorInicial !== 0) {
            crescimentoMensal = ((valorFinal / valorInicial) ** (1 / (meses - 1)) - 1) * 100
          }
        }

        return crescimentoMensal
      },
      crescimentoMedioDiario () {
        let crescimentoDiario = 0

        const meses = this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'months') + 1

        if (meses <= 1) {
          return 0 // Se houver apenas um mês, o crescimento é zero
        }

        const primeiroMes = this.dashboard.grafico_volume.data.series[1].data[0]
        const ultimoMes = this.dashboard.grafico_volume.data.series[1].data[meses - 1]

        if (primeiroMes && primeiroMes.value !== undefined && ultimoMes && ultimoMes.value !== undefined) {
          const valorInicial = parseInt(primeiroMes.value)
          const valorFinal = parseInt(ultimoMes.value)

          if (!isNaN(valorInicial) && !isNaN(valorFinal) && valorInicial !== 0) {
            // Calcula o número de dias do primeiro mês
            const diasPrimeiroMes = this.moment(this.moment(this.searchDates.from).format('YYYY-MM')).daysInMonth()

            // Calcula o número de dias do último mês de acordo com a data atual
            const diasUltimoMes = this.moment(this.searchDates.to).date()

            // Converte o valor mensal para valor diário
            const valorDiarioInicial = valorInicial / diasPrimeiroMes
            const valorDiarioFinal = valorFinal / diasUltimoMes

            // Fórmula para calcular o crescimento médio diário
            crescimentoDiario = ((valorDiarioFinal / valorDiarioInicial) ** (1 / ((meses * diasUltimoMes) - 1)) - 1) * 100
          }
        }

        return crescimentoDiario
      },
      coletado () {
        let latestValue = 0

        if (this.dashboard.grafico_volume.data.series[1].data[11]) {
          latestValue = parseFloat(this.dashboard.grafico_volume.data.series[1].data[11].value)
        }

        return latestValue
      },
      labelGraficoComparativo () {
        switch (this.searchDates.label) {
          case 2:
            return ['Mês anterior', 'Mês atual']
          case 3:
            return ['Trimestre anterior', 'Trimestre atual']
          case 4:
            return ['Semestre anterior', 'Semestre atual']
          case 5:
            return ['Ano anterior', 'Ano atual']
          case 6:
            return ['Período anterior', 'Período atual']
          default:
            return ['teste', 'teste']
        }
      },
      labelGrafico () {
        switch (this.searchDates.label) {
          case 2:
            return 'Mês atual'
          case 3:
            return 'Trimestre atual'
          case 4:
            return 'Semestre atual'
          case 5:
            return 'Ano atual'
          case 6:
            return 'Período ' + this.searchDates.selected
          default:
            return this.searchDates.label
        }
      },
    },
    mounted () {
      this.geolocate()
    },

    methods: {
      ...mapActions('volumes', [BOOTSTRAP_DASHBOARD]),
      geolocate: function () {
        const location = { lat: 0, lng: 0 }
        function errorCallback (erro) {
          const { code } = erro
          switch (code) {
            case GeolocationPositionError.TIMEOUT:
              window.navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback)
              break
            case GeolocationPositionError.PERMISSION_DENIED:
              Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Foi negado a Plataforma acesso as informações de localização do usuário!',
              })
              break
            case GeolocationPositionError.POSITION_UNAVAILABLE:
              Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'As informações de localização do usuário são inválidas!',
              })
              break
          }
        }

        function successCallback (position) {
          location.lat = position.coords.latitude
          location.lng = position.coords.longitude
        }

        if (navigator.geolocation) {
          try {
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback,
              { enableHighAccuracy: true, timeout: 5000, maximumAge: 600000 },
            )
          } catch {
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback)
          }
        }
        this.center = location
      },
      pontos (options) {
        var defaultOptions = {
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -10,
          },
          textAnchor: 'middle',
          align: 'center',
          labelInterpolationFnc: this.$chartist.noop,
        }

        var labelPositionCalculation = {
          point: function (data) {
            return {
              x: data.x,
              y: data.y,
            }
          },
          bar: {
            left: function (data) {
              return {
                x: data.x1,
                y: data.y1,
              }
            },
            top: function (data) {
              return {
                x: data.x1,
                y: data.y2,
              }
            },
            center: function (data) {
              return {
                x: data.x1 + (data.x2 - data.x1) / 2,
                y: data.y1,
              }
            },
            right: function (data) {
              return {
                x: data.x2,
                y: data.y1,
              }
            },
          },
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        function addLabel (position, data) {
          var meta = data.meta
          var value = data.value.x !== undefined && data.value.y
            ? (data.value.x + ', ' + data.value.y)
            : data.value.y || data.value.x

          value = value === undefined ? 0 : value

          data.group.elem('text', {
            x: position.x + options.labelOffset.x,
            y: position.y + options.labelOffset.y,
            style: 'text-anchor: ' + options.textAnchor,
          }, options.labelClass).text(options.labelInterpolationFnc(meta, value))
        }

        return function ctPointLabels (chart) {
          chart.on('draw', function (data) {
            var positonCalculator = (labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align]) || labelPositionCalculation[data.type]
            if (positonCalculator) {
              addLabel(positonCalculator(data), data)
            }
          })
        }
      },
      optVolume () {
        return {
          low: 0,
          showArea: false,
          seriesBarDistance: 20,
          axisX: {
            offset: 20,
          },
          axisY: {
            onlyInteger: true,
            offset: 100,
            labelInterpolationFnc: function (value) {
              return currencyFormatter(value, 0) + ' L'
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            this.$chartist.plugins.legend({ legendNames: this.labelGraficoComparativo }),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + currencyFormatter(value, 0) + ' L' },
            }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },
      optLine () {
        return {
          axisX: {
            offset: 30,
          },
          axisY: {
            onlyInteger: false,
            offset: 100,
            labelOffset: {
              x: -15,
              y: 0,
            },
            labelInterpolationFnc: function (value) {
              return currencyFormatter(value, 0) + ' L   '
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0,
          }),
          height: 290,
          low: 0,
          showArea: true,
          plugins: [
            this.$chartist.plugins.legend({ legendNames: this.labelGraficoComparativo }),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + 'Média ' + currencyFormatter(value, 0) + ' L' },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (meta, value) { return currencyFormatter(value, 0) },
            }),
          ],
          chartPadding: {
            top: 50,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },

    },
  }
</script>

<style scoped>

.grafico .ct-square {
  height: 290px;
}

.chartist-tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: #333;
  font-size: 12px;
  padding: 10px;
  position: absolute;
  z-index: 10000;
}

.v-card--material-chart .v-card--material__heading {
  max-height: 295px !important;
}

.grafico .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

</style>
